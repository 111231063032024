import './App.scss';
import Main from "./containers/Main";


function App() {
  return (
      <div className="App">
          <Main />
      </div>
  );
}

export default App;
